.cards {
    margin-left: 15px;
}

.cardsText {
    text-align: center;
}



.expand-skills {
    transform: scale(0.94);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  
}


.blur-languages {
    opacity: 0;
    filter: blur(4px);
  }
  
  .blur-languages:nth-child(1) {
    animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .blur-languages:nth-child(2) {
    animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .blur-languages:nth-child(3) {
    animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .blur-languages:nth-child(4) {
    animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .blur-languages:nth-child(5) {
    animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  

  .blur-libraries {
    opacity: 0;
    filter: blur(4px);
  }
  
  .blur-libraries:nth-child(1) {
    animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .blur-libraries:nth-child(2) {
    animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .blur-libraries:nth-child(3) {
    animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .blur-libraries:nth-child(4) {
    animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .blur-libraries:nth-child(5) {
    animation: fade-in 0.8s 1.0s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  .blur-libraries:nth-child(6) {
    animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  

  .blur-libraries:nth-child(7) {
    animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  

  .blur-libraries:nth-child(8) {
    animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  


