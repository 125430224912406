ul {
    list-style: none;
}


.crdimg {
    position: relative;
    top: 20px;
}




.expand-projects {
    transform: scale(0.94);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  
}


.blur-projects1 {
    opacity: 0;
    filter: blur(4px);
    animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur-projects2 {
    opacity: 0;
    filter: blur(4px);
    animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur-projects3 {
    opacity: 0;
    filter: blur(4px);
    animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}


