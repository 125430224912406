.parent {
  margin: 13rem  0 0 2rem;
    text-align: start;
  }
  
  

.expand {
  max-width: 40ch;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}

.blur {
  opacity: 0;
  filter: blur(4px);
}

.blur:nth-child(1) {
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.blur:nth-child(2) {
    animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.button {
  opacity: 0;
  filter: blur(4px);
  animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}



.animated:hover{
  opacity: 1;
  filter: blur(0);
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-name: tada;
}



@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    -ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

/* Media queries for tablets (devices with screens roughly between 768px and 1024px) */
@media (max-width: 1024px) and (min-width: 768px) {
  .parent {
    margin: 10rem 0 0 1rem; /* Adjust margin to accommodate navbar if needed */
    text-align: start;
  }

  .expand {
    max-width: 30ch;
    transform: scale(0.85);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  }

  .blur {
    filter: blur(3px);
  }

  .button {
    opacity: 0.9;
    filter: blur(3px);
    animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
}

/* Media queries for phones (devices with screens less than 768px) */
@media (max-width: 767px) {
  .flexed {
    display: flex;
    flex-direction: column;  /* Stacks children vertically */
    align-items: center;     /* Optional: Horizontally center-align items */
    justify-content: center; /* Optional: Vertically center-align items */
}
.template {
  width: auto;
}

.expand-contact{
 margin-left: 0 !important;
}

.d-flex {
  display: flex;
  flex-direction: column;  /* Stacks children vertically */
  align-items: center;     /* Optional: Horizontally center-align items */
  justify-content: center; /* Optional: Vertically center-align items */
}


}